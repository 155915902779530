<template>
  <li>
    <span
      class="no-wrap pointer"
      :class="{'text-success': item.isExist}"
      @click="toggle = !toggle">
      {{item.title}}
    </span>
    <ul
      v-show="toggle && item.sections && item.sections.length > 0">
      <list-link-item
        v-for="child in item.sections"
        :item="child"
        :key="child.id"
        :templates="templates"
        :groupRoles="groupRoles"
        :groupId="groupId"
        :groupType="groupType"
        :templateType="templateType"
        @bus="bus"
        @updated="emitUpdated">
      </list-link-item>
    </ul>

    <ul v-if="roles.length > 0">
      <li
        v-for="role in roles"
        :key="role.code">
        <span :class="{'text-success': role.isExist}">
          <span
            v-if="!role.isExist"
            class="text-success pointer px-1"
            @click="addRole(role.id)">
            <fa icon="plus"></fa>
          </span>
          <span
            v-if="role.isExist"
            class="text-danger pointer px-1"
            @click="deleteRole(role.id)">
            <fa icon="trash"></fa>
          </span>
          {{role.name}}
        </span>
      </li>
    </ul>
  </li>
</template>

<script>
import {
  CREATE_GROUP_ROLE,
  DESTROY_GROUP_ROLE
} from './graph'

export default {
  props: {
    groupId: {
      type: Number,
      required: true
    },
    groupType: {
      type: String,
      required: true
    },
    groupRoles: {
      type: Array,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    templates: {
      type: Array,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  name: 'list-link-item',
  data () {
    return {
      toggle: false
    }
  },
  computed: {
    roles () {
      const found = this.templates.find(v => v.template === this.item.template)

      if (!found) return []

      const typeKey = Object.keys(this.item.options)[0]
      const type = this.item.options[typeKey]

      return found.roles
      .filter(r => {
        if (!r) return false
        const roleCode = r.code.split(':')[1]
        return roleCode === type
      })
      .map(r => {
        return {
          id: r.id,
          code: r.code,
          name: r.name,
          isExist: this.groupRoles.some(g => g === r.code)
        }
      })
    }
  },
  methods: {
    bus (value) {
      this.$emit('bus', value)
    },
    addRole (roleId) {
      this.$apollo.mutate({
        mutation: CREATE_GROUP_ROLE(this.templateType),
        variables: {
          groupType: this.groupType,
          groupId: this.groupId,
          roleId
        }
      })
      .then(() => {
        this.emitUpdated()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    deleteRole (roleId) {
      this.$apollo.mutate({
        mutation: DESTROY_GROUP_ROLE(this.templateType),
        variables: {
          groupType: this.groupType,
          groupId: this.groupId,
          roleId
        }
      })
      .then(() => {
        this.emitUpdated()
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    emitUpdated () {
      this.$emit('updated', null)
    }
  }
}
</script>

<style lang="css" scoped>
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

* {
  position: relative;
  margin: 0;
  padding: 0;

  border: 0 none;

  -webkit-transition: all ease .4s;
  -moz-transition: all ease .4s;
    transition: all ease .4s;
}

ul.tree, ul.tree ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul.tree ul {
  margin-left: 10px;
}
ul.tree li {
  margin: 0;
  padding: 0 7px;
  line-height: 20px;
  border-left:1px solid rgb(100,100,100);
}
ul.tree li:last-child {
  border-left:none;
}
ul.tree li:before {
  position:relative;
  top:-0.3em;
  height:1em;
  width:12px;
  color:white;
  border-bottom:1px solid rgb(100,100,100);
  content:"";
  display:inline-block;
  left:-7px;
}
ul.tree li:last-child:before {
  border-left:1px solid rgb(100,100,100);
}
</style>
