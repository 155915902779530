<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">{{title}}</h3>
      </div>
    </div>

    <hr class="my-3">

    <sgv-form :method.sync="method" :options="options">

      <div>
        <div class="form-row">
          <sgv-input-text
            :disabled="$auth.disabled(method)"
            class="col-12 col-sm-4"
            label="รหัส"
            v-model="formData.code"
            :validations="[
              {text: 'required!', value: $v.formData.code.$dirty && $v.formData.code.$error}
            ]">
          </sgv-input-text>

          <sgv-input-text
            :disabled="$auth.disabled(method)"
            class="col-12 col-sm-8"
            label="ชื่อ"
            v-model="formData.name">
          </sgv-input-text>
        </div>
      </div>
    </sgv-form>

    <HrDivider :options="tabs" v-model="selectedTab" class="mt-3"></HrDivider>

    <DetailFolder
      v-if="selectedTab === 'folder'"
      :method="method"
      :groupId="groupId"
      :groupRoles="groupRoles"
      :templateType="templateType"
      :groupType="groupType"
      @updated="dataDetail">
    </DetailFolder>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {
  DETAIL_GROUP,
  CREATE_GROUP,
  UPDATE_GROUP,
  DESTROY_GROUP
} from './graph'
import DetailFolder from './DetailFolder.vue'

export default {
  props: {
    groupId: {
      type: Number,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    groupType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      listView: `Group${this.$form.capitalize(this.groupType)}List`,
      formData: {
        code: '',
        name: '',
        roles: []
      },
      method: 'info',
      tabs: [
        {text: 'แผนผัง', value: 'folder'}
      ],
      selectedTab: 'folder',
    }
  },
  validations: {
    formData: {
      code: { required },
    }
  },
  computed: {
    groupRoles () {
      return this.formData?.roles.map(v => v.code) || []
    },
    options () {
      return [
        {
          text: 'เพิ่ม',
          variant: 'success',
          method: 'add',
          func: this.createData,
          disabled: true
        },
        {
          text: 'แก้ไข',
          variant: 'warning',
          method: 'edit',
          func: this.updateData,
          disabled: this.method === 'add' || !this.$auth.hasRole(`group:${this.groupType}:add`)
        },
        {
          text: 'ลบ',
          variant: 'danger',
          method: 'delete',
          func: this.destroyData,
          disabled: this.method === 'add' || !this.$auth.hasRole(`group:${this.groupType}:add`)
        },
        {
          text: 'กลับ',
          variant: 'primary',
          func: this.closeForm,
          header: true,
          align: 'right'
        },
      ]
    }
  },
  methods: {
    dataDetail () {
      this.$apollo.query({
        query: DETAIL_GROUP(this.templateType),
        variables: {
          groupType: this.groupType,
          groupId: this.groupId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.setFormData(res.data.group)
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
      .finally(() => {this.isLoaded = true})
    },
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        this.formData[key] = v[key]
      })
    },
    serializeInput (v) {
      return  {
        code: v.code,
        name: v.name,
      }
    },
    createData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: CREATE_GROUP(this.templateType),
        variables: {
          groupType: this.groupType,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$router.push({
          name: this.$route.name,
          params: {...this.$route.params, groupId: res.data.createGroup.id},
          query: {...this.$route.query, redirect: this.listView}
        })
      })
      .catch(this.$toasted.global.error)
    },
    updateData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const input = this.serializeInput(this.formData)

      this.$apollo.mutate({
        mutation: UPDATE_GROUP(this.templateType),
        variables: {
          groupType: this.groupType,
          groupId: this.groupId,
          input
        }
      })
      .then(res => {
        this.setFormData(res.data.updateGroup)
        this.method = 'info'
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_GROUP(this.templateType),
        variables: {
          groupType: this.groupType,
          groupId: this.groupId
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.closeForm()
      })
      .catch(this.$toasted.global.error)
    },
    closeForm () {
      if (this.$route.query.redirect) {
        if (this.$route.query.redirect === this.listView) {
          this.$router.push({name: this.listView})
        } else {
          this.$router.go(-1)
        }
      } else {
        this.$router.push({name: this.listView})
      }
    },
  },
  created () {
    if (this.groupId === 0) {
      this.method = 'add'
      this.isLoaded = true
    } else {
      this.dataDetail()
    }
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    DetailFolder
  }
}
</script>

<style lang="css" scoped>
</style>
