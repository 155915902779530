import gql from 'graphql-tag'


export const LIST_GROUP = (templateType) => gql`query LIST_GROUP ($groupType: String!, $q: FilterInput) {
  groups: listGroup${templateType} (groupType: $groupType, q: $q) {
    id code name
  }
}`

export const DETAIL_GROUP = (templateType) => gql`query DETAIL_GROUP ($groupType: String!, $groupId: Int!) {
  group: detailGroup${templateType} (groupType: $groupType, groupId: $groupId) {
    id code name
    roles {id code name}
  }
}`

export const CREATE_GROUP = (templateType) => gql`mutation CREATE_GROUP ($groupType: String!, $input: Group${templateType}Input!) {
  createGroup: createGroup${templateType} (groupType: $groupType, input: $input) {
    id code name
    roles {id code name}
  }
}`

export const UPDATE_GROUP = (templateType) => gql`mutation UPDATE_GROUP ($groupType: String!, $groupId: Int!, $input: Group${templateType}Input!) {
  updateGroup: updateGroup${templateType} (groupType: $groupType, groupId: $groupId, input: $input) {
    id code name
    roles {id code name}
  }
}`

export const DESTROY_GROUP = (templateType) => gql`mutation DESTROY_GROUP ($groupType: String!, $groupId: Int!) {
  destroyGroup: destroyGroup${templateType} (groupType: $groupType, groupId: $groupId) {id}
}`

export const CREATE_GROUP_ROLE = (templateType) => gql`mutation CREATE_GROUP_ROLE ($groupType: String!, $groupId: Int!, $roleId: Int!) {
  createGroupRole: createGroup${templateType}Role (groupType: $groupType, groupId: $groupId, roleId: $roleId) {
    groupId roleId
  }
}`

export const DESTROY_GROUP_ROLE = (templateType) => gql`mutation DESTROY_GROUP_ROLE ($groupType: String!, $groupId: Int!, $roleId: Int!) {
  destroyGroupRole: destroyGroup${templateType}Role (groupType: $groupType, groupId: $groupId, roleId: $roleId) {
    groupId roleId
  }
}`

export const DP_ROLES = (templateType) => gql`query DP_ROLES ($groupType: String!, $q: FilterInput) {
  roles: listGroup${templateType}DropdownRole (groupType: $groupType, q: $q) {
    id code name
  }
}`

export const TEMPLATE_ROLES = (templateType) => gql`query TEMPLATE_ROLES ($groupType: String!, $input: [String!]!) {
  templates: listGroup${templateType}TemplateRole (groupType: $groupType, input: $input) {
    template roles {
      id code name
    }
  }
}`
