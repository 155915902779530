<template>
  <div>
    <ul class="tree">
      <DetailFolderItem
        v-for="item in optionsMapped"
        :item="item"
        :key="item.title"
        @bus="$emit('selected', $event)"
        @updated="emitUpdated"
        :templates="templates"
        :templateType="templateType"
        :groupRoles="groupRoles"
        :groupId="groupId"
        :groupType="groupType">
      </DetailFolderItem>
    </ul>
  </div>
</template>

<script>
import { setup as APP_SETUP } from '@/router'
import DetailFolderItem from './DetailFolderItem'
import { TEMPLATE_ROLES } from './graph'

export default {
  props: {
    groupId: {
      type: Number,
      required: true
    },
    groupRoles: {
      type: Array,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    groupType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      options: APP_SETUP.routers,
      templates: []
    }
  },
  computed: {
    optionsMapped () {
      const arr = [...this.options]
      const temps = this.templates
      .filter(v => v.roles.length > 0)
      .filter(v => {
        return v.roles.some(r => {
          if (!r) return false
          return this.groupRoles.indexOf(r.code) >= 0
        })
      })

      arr.forEach(p => {
        p.isExist = false
        p.sections.forEach(s => {
          s.isExist = false
          s.sections.forEach(child => {
            child.isExist = false

            const typeKey = Object.keys(child.options)[0]
            const childTemplate = child.template
            const childType = child.options[typeKey]

            const found = temps.find(t => {
              const isTemplate = t.template === childTemplate
              const isRole = t.roles
              .filter(r => {
                if (!r) return false
                const roleCode = r.code.split(':')[1]
                return roleCode === childType
              })
              .findIndex(r => this.groupRoles.includes(r.code)) !== -1

              return isTemplate && isRole
            })

            if (found) {
              p.isExist = true
              s.isExist = true
              child.isExist = true
            }
          })
        })
      })

      return arr
    }
  },
  apollo: {
    templates: {
      query () {
        return TEMPLATE_ROLES(this.templateType)
      },
      variables () {
        return {
          groupType: this.groupType,
          input: this.serializeInput(this.options)
        }
      },
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    mapOption (v) {
      const isExist = v.template && this.templatesMapped.some(x => x.isExist && x.template === v.template)
      return {
        title: v.title,
        template: v.template,
        sections: v.sections,
        isExist
      }
    },
    serializeInput (options) {
      const arr = options
      .flatMap(v => v.sections)
      .flatMap(v => v.sections)
      .map(v => v.template)

      return [...new Set(arr)]
    },
    emitUpdated () {
      this.$emit('updated', null)
    }
  },
  components: {
    DetailFolderItem
  }
}
</script>

<style lang="css" scoped>
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

* {
  position: relative;
  margin: 0;
  padding: 0;

  border: 0 none;

  -webkit-transition: all ease .4s;
  -moz-transition: all ease .4s;
    transition: all ease .4s;
}

ul.tree, ul.tree ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul.tree ul {
  margin-left: 10px;
}
ul.tree li {
  margin: 0;
  padding: 0 7px;
  line-height: 20px;
  border-left:1px solid rgb(100,100,100);
}
ul.tree li:last-child {
  border-left:none;
}
ul.tree li:before {
  position:relative;
  top:-0.3em;
  height:1em;
  width:12px;
  color:white;
  border-bottom:1px solid rgb(100,100,100);
  content:"";
  display:inline-block;
  left:-7px;
}
ul.tree li:last-child:before {
  border-left:1px solid rgb(100,100,100);
}
</style>
